import setName from "./actions/setName"
import setContact from "./actions/setContact"

export function showDialog(id) {
  const element = document.getElementById("message-dialog")
  window.stagecraft.spotlight(element)
}

export function setupDialog() {
  const element = document.getElementById("message-dialog")
  element.addEventListener("messageSent", function(event) {
    window.stagecraft.flood()
  })
}

export function messageForm(id, post) {
  const container = document.getElementById(id)
  if (!container) {
    throw new Error("Missing message form container: " + id)
  }

  const form = container.getElementsByTagName("form")[0]
  const name = form.querySelector("input[name=name]")
  const contact = form.querySelector("input[name=contact]")
  const submit = form.querySelector("button[type=submit]")
  const message = form.querySelector("textarea[name=message]")

  function update() {
    const state = window.store.getState()
    name.value = state.name || ""
    contact.value = state.contact || ""
  }

  window.store.subscribe(update)
  update()

  name.addEventListener("change", (event) => {
    window.store.dispatch(setName(name.value))
  })

  contact.addEventListener("change", (event) => {
    window.store.dispatch(setContact(contact.value))
  })

  form.addEventListener("submit", (event) => {
    event.preventDefault()
    const body = {
      name: name.value,
      contact: contact.value,
      message: message.value,
    }
    fetch(
      "https://api.kanook.net/v1/comments/" + encodeURIComponent(post),
      {
        body: JSON.stringify(body),
        credentials: "include",
        method: "POST",
        mode: "no-cors",
      }
    ).then(
      (response) => {
        container.dispatchEvent(new CustomEvent("messageSent"))
      },
      (error) => {
        console.error("Error on message send", id, error)
        container.dispatchEvent(new CustomEvent("messageSendError", { error }))
      })
  })
}

export default showDialog
